import './App.css';
import logo from './images/logo_small.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Команда Rain-studio больше не принимает заказы и работает только на внутренние нужды VariusSoft
        </p>
        <p>
          По интересующим вопросам обращайтесь на <a className='App-link' href='mailto:nikolas@rain-studio.com'>почту</a>
        </p>
        <p className='Secret-text'>И да, это стандартное Реакт приложение, которое мы даже не постарались поменять</p>
      </header>
    </div>
  );
}

export default App;
